






































































































































import { RequestPaging } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import TemplateSelect from "@/components/shop/template-select/index.vue";
import LsPagination from "@/components/ls-pagination.vue";
import {
    apiThemePageAdd,
    apiThemePageDel,
    apiThemePageLists,
    apiThemePageSetHome,
} from "@/api/shop";

import { Action } from "vuex-class";

@Component({
    components: {
        LsDialog,
        LsPagination,
        TemplateSelect,
    },
})
export default class PagesLists extends Vue {
    @Action("getConfig") getConfig!: () => void;
    queryObj = {
        name: "",
    };
    pager = new RequestPaging();
    storeId: Number = 0;
    getList(page?: number): void {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiThemePageLists,
            params: {
                ...this.queryObj,
            },
        });
    }
    handleReset() {
        this.queryObj = {
            name: "",
        };
        this.getList();
    }

    handleDelete(id: number) {
        apiThemePageDel({ id }).then(() => {
            this.getList();
        });
    }
    handleSetHome(id: number) {
        apiThemePageSetHome({ id }).then(() => {
            this.getList();
        });
    }
    handleSelect(data: any) {
        apiThemePageAdd(data).then((res) => {
            this.$router.push({
                path: "/decorate/index",
                query: { id: res.id, API_TYPE: "2" },
            });
        });
    }

    async created() {
        this.getList();
        // const data: any = await this.getConfig();
        this.storeId = this.$store.state.app.storeId;
    }
}
