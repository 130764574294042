var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pages-lists"},[_c('div',{staticClass:"pages-lists__top ls-card"},[_c('el-alert',{attrs:{"title":"温馨提示：微页面指定为店铺首页后不能删除。","type":"info","closable":false,"show-icon":""}}),_c('div',{staticClass:"ls-top__search m-t-16"},[_c('el-form',{ref:"form",attrs:{"inline":"","model":_vm.queryObj,"label-width":"80px","size":"small"}},[_c('el-form-item',{attrs:{"label":"页面名称"}},[_c('el-input',{staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入页面名称"},model:{value:(_vm.queryObj.name),callback:function ($$v) {_vm.$set(_vm.queryObj, "name", $$v)},expression:"queryObj.name"}})],1),_c('el-form-item',{staticClass:"m-l-20",attrs:{"label":""}},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("查询")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)],1)],1),_c('div',{staticClass:"pages-lists__content ls-card m-t-16"},[_c('div',{staticClass:"ls-content__btns"},[_c('template-select',{on:{"select":_vm.handleSelect}},[(!_vm.$store.state.app.storeId)?_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v("新建微页面")]):_vm._e()],1)],1),_c('div',{staticClass:"ls-content__table m-t-16"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pager.loading),expression:"pager.loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.pager.lists,"size":"mini"}},[_c('el-table-column',{attrs:{"label":"页面名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"m-r-10"},[_vm._v(_vm._s(scope.row.name))]),(
                                scope.row.is_home &&
                                scope.row.packge_id == _vm.storeId
                            )?_c('el-tag',{attrs:{"size":"mini"}},[_vm._v("首页")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"update_time","label":"更新时间"}}),_c('el-table-column',{attrs:{"prop":"create_time","label":"创建时间"}}),(!_vm.$store.state.app.storeId)?_c('el-table-column',{attrs:{"fixed":"right","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"inline m-r-10"},[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                                        path: '/control-center/shop/shop_index_cc',
                                        query: {
                                            id: scope.row.id,
                                            ccid: _vm.$route.query.ccid,
                                        },
                                    })}}},[_vm._v("编辑")])],1),(!scope.row.is_home)?[_c('ls-dialog',{staticClass:"inline m-r-10",attrs:{"content":("确定删除：" + (scope.row.name) + "？请谨慎操作。")},on:{"confirm":function($event){return _vm.handleDelete(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("删除")])],1),_c('ls-dialog',{staticClass:"inline",attrs:{"content":("确定设置页面：" + (scope.row.name) + "为首页？")},on:{"confirm":function($event){return _vm.handleSetHome(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("设置首页")])],1)]:_vm._e()]}}],null,false,683762524)}):_vm._e()],1)],1),_c('div',{staticClass:"flex row-right m-t-16"},[_c('ls-pagination',{on:{"change":function($event){return _vm.getList()}},model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }